import { twAMFutures, twPMFutures, twAutoSessionFutures, cmeFutures, cbotFutures, cbotC, nybotKC, nybotSB, nybotCC, sgxTWN, sgxCN, sgxNK, sgxIN, hkfFutures, hkfMTW, eurex, usStock, foreign, } from './symbolGroups';
import { twStockTicks, twAMFuturesTicks, twPMFuturesTicks, twAutoSessionTicks, cmeFuturesTicks, sgxCNTicks, usStockTicks, allDayTicks, sgxTWNTicks, sgxNKTicks, sgxINTicks, cbotFuturesTicks, cbotCTicks, nybotSBTicks, nybotKCTicks, nybotCCTicks, hkfFuturesTicks, hkfMTWTicks, eurexFuturesTicks, foreignTicks, } from './specTicksSettingUtil';
import dayAPI from '~/utils/dayAPI';
export const getSymbolTicks = (symbol, date) => {
    if (symbol?.match(/^\d{4,5}[A-Z]?$/))
        return twStockTicks(date);
    if (symbol?.match(/^[A-Z]{2}F-\d+$/))
        return twAMFuturesTicks(date); // 股期
    if (symbol?.match(/^TX[12O45]\d{5}[A-X]\d$/))
        return twAutoSessionTicks(date); // 選擇權
    if (symbol?.match(/^(TSE\w*|OTCA|TWXI)$/))
        return twStockTicks(date); // 國內指數
    const splitPos = symbol?.indexOf('-');
    const baseSymbol = splitPos == -1 ? symbol : symbol?.substring(0, splitPos);
    if (twAutoSessionFutures.indexOf(baseSymbol) != -1)
        return twAutoSessionTicks(date);
    if (twAMFutures.indexOf(baseSymbol) != -1)
        return twAMFuturesTicks(date);
    if (twPMFutures.indexOf(baseSymbol) != -1)
        return twPMFuturesTicks(date);
    if (nybotKC.indexOf(baseSymbol) != -1)
        return nybotKCTicks(date);
    if (nybotSB.indexOf(baseSymbol) != -1)
        return nybotSBTicks(date);
    if (nybotCC.indexOf(baseSymbol) != -1)
        return nybotCCTicks(date);
    if (sgxTWN.indexOf(baseSymbol) != -1)
        return sgxTWNTicks(date);
    if (sgxCN.indexOf(baseSymbol) != -1)
        return sgxCNTicks(date);
    if (sgxNK.indexOf(baseSymbol) != -1)
        return sgxNKTicks(date);
    if (sgxIN.indexOf(baseSymbol) != -1)
        return sgxINTicks(date);
    if (hkfFutures.indexOf(baseSymbol) != -1)
        return hkfFuturesTicks(date);
    if (hkfMTW.indexOf(baseSymbol) != -1)
        return hkfMTWTicks(date);
    if (cmeFutures.indexOf(baseSymbol) != -1)
        return cmeFuturesTicks(date);
    if (cbotFutures.indexOf(baseSymbol) != -1)
        return cbotFuturesTicks(date);
    if (cbotC.indexOf(baseSymbol) != -1)
        return cbotCTicks(date);
    if (eurex.indexOf(baseSymbol) != -1)
        return eurexFuturesTicks(date);
    if (usStock.indexOf(baseSymbol) != -1)
        return usStockTicks(date);
    if (foreign.indexOf(baseSymbol) != -1)
        return foreignTicks(date);
    return allDayTicks(dayAPI());
};
