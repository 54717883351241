import { useTheme } from '@emotion/react';
/**
 * 拿取「最近一層 <ThemeProvider />」所提供的 `theme`
 *
 * 不同於 `useThemeStore` 它其實拿的只是「最近一層」而非最上層
 *
 * 用途於：像 futuresai 主站，外層是 LightTheme 但裡層 component UI 要 DarkTheme
 *
 * 主要用於防止黑白錯亂
 *
 * @example
 *   //
 *   // 以這種
 *   const parentTheme = useThemeOfParent()
 *   parentTheme.isDark // true
 *   parentTheme.isLight // true
 *
 *   //
 *   // 來取代
 *   const theme = useThemeStore(state => state.theme)
 *   const themeColor = theme === 'dark'
 */
export const useThemeOfParent = () => {
    const currentThemeOfParent = useTheme();
    return {
        theme: currentThemeOfParent,
        themeKey: currentThemeOfParent.palette.mode,
        isDark: currentThemeOfParent.palette.mode === 'dark',
        isLight: currentThemeOfParent.palette.mode === 'light',
    };
};
