import { Fragment, memo } from 'react';
import { remoteSupportSymbols } from '~/configs/remoteSupportSymbols';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
/** 顯示 Symbol 人類可讀的名稱 */
export const SymbolName = memo(function SymbolName(props) {
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const _symbolName = dictionary[props.symbol ?? ''] ||
        // fallback, provide some default key/value
        remoteSupportSymbols[props.symbol]?.description ||
        props.symbol;
    return <Fragment>{_symbolName || 'No Symbol Found'}</Fragment>;
});
