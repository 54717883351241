import useSWR from 'swr-0-5-6';
import urlcat from 'urlcat';
import { apirc } from '~/configs/apirc';
// 暫定以日為單位
// intraday & fromTo 設定擇一
export const useSymbolHistoryResource = (options) => {
    const symbol = options?.symbol || '2330';
    const resolution = options?.resolution || '1D';
    const intraday = options?.intraday || undefined;
    const refreshInterval = options?.refreshInterval || 10000;
    const fromTo = intraday
        ? [undefined, undefined]
        : options?.fromTo && options.fromTo.length === 2
            ? options.fromTo
            : [undefined, undefined];
    const api = urlcat(`${apirc.tvAPIs.baseUrl}/history`, {
        symbol,
        resolution,
        from: fromTo[0],
        to: fromTo[1],
        ws: true,
        intraday,
    });
    const res = useSWR(api, {
        revalidateOnFocus: false,
        refreshInterval: refreshInterval,
    });
    return res;
};
// 暫定以日為單位，call only once
export const useSymbolHistoryOnceResource = (options) => {
    const symbol = options?.symbol || '2330';
    const resolution = options?.resolution || '1D';
    const fromTo = options?.fromTo && options.fromTo.length === 2 ? options.fromTo : [0, 0];
    const api = urlcat(`${apirc.tvAPIs.baseUrl}/history`, {
        symbol,
        resolution,
        from: fromTo[0],
        to: fromTo[1],
        ws: true,
    });
    const res = useSWR(api, {
        revalidateOnFocus: false,
    });
    return res;
};
